import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import React from "react";
const VAHome = React.lazy(() => import('./components/virtual-agent/VA-Home'));
const CCHome = React.lazy(() => import('./components/compatibility-checker/CC-Home'));
const FeedbackHome = React.lazy(() => import('./components/feedback/Feedback-Home'));
const MasterTemplateVA = React.lazy(() => import('./components/master-template-va/MasterTemplateVA-Home'));
const AppStore = React.lazy(() => import('./components/master-template-va/MasterTemplateVA-AppStore'));
const App = () => {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <React.Suspense fallback={<></>}>
              <MasterTemplateVA behavior="default" />
            </React.Suspense>
          } />
        <Route
          path="/urcsupport.html"
          element={
            <React.Suspense fallback={<></>}>
              <VAHome />
            </React.Suspense>
          } />
        <Route
          path="/compatibilitychecker.html"
          element={
            <React.Suspense fallback={<></>}>
              <CCHome />
            </React.Suspense>
          } />
        <Route
          path="/feedback.html"
          element={
            <React.Suspense fallback={<></>}>
              <FeedbackHome />
            </React.Suspense>
          } />
        <Route
          path="/:source/:category/:provider?/:product?/:feature?/:optional?"
          element={
            <React.Suspense fallback={<></>}>
              <MasterTemplateVA behavior="default" />
            </React.Suspense>
          } />
        <Route
          path="/nouiassistedsetup/:deeplinkId/:sessionId"
          element={
            <React.Suspense fallback={<></>}>
              <MasterTemplateVA behavior="nouiassistedsetup" />
            </React.Suspense>
          } />
        <Route
          path="/feedback/:deeplinkId/:sessionId"
          element={
            <React.Suspense fallback={<></>}>
              <MasterTemplateVA behavior="feedback" />
            </React.Suspense>
          } />
        <Route
          path="/onboarding/:deeplinkId"
          element={
            <React.Suspense fallback={<></>}>
              <MasterTemplateVA behavior="onboarding" />
            </React.Suspense>
          } />
        <Route
          path="/quicksetauth/:deeplinkId"
          element={
            <React.Suspense fallback={<></>}>
              <MasterTemplateVA behavior="quicksetauth" />
            </React.Suspense>
          } />
        <Route
          path="/vaassist/:sessionId"
          element={
            <React.Suspense fallback={<></>}>
              <MasterTemplateVA behavior="vaassist" />
            </React.Suspense>
          } />
        <Route
          path="/addmatteraccessory/:sessionId"
          element={
            <React.Suspense fallback={<></>}>
              <MasterTemplateVA behavior="addmatteraccessory" />
            </React.Suspense>
          } />
        <Route
          path="/leave-feedback"
          element={
            <React.Suspense fallback={<></>}>
              <MasterTemplateVA behavior="leave-feedback" />
            </React.Suspense>
          } />
        <Route
          path="/qr"
          element={
            <React.Suspense fallback={<></>}>
              <AppStore />
            </React.Suspense>
          } />
      </Routes>
    </Router>
  );
};

export default App;
// leave-feedback