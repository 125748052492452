import { configureStore } from "@reduxjs/toolkit";
import carouselSlice from "./carouselSlice";
import vaStateSlice from "./vaStateSlice";
import feedbackStateSlice from "./feedbackStateStore";
import ccStateSlice from "./ccStateSlice";
import activitySlice from "./activitySlice";
import masterTemplateVaSlice from "./masterTemplateVaSlice";

const store = configureStore({
    reducer: {
        carouselStore: carouselSlice.reducer,
        vaStateStore: vaStateSlice.reducer,
        feedbackStateStore: feedbackStateSlice.reducer,
        ccStateStore: ccStateSlice.reducer,
        activityStore: activitySlice.reducer,
        masterTemplateVaStore: masterTemplateVaSlice.reducer
    }
});
export default store;