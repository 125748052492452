import { createSlice } from "@reduxjs/toolkit";

const masterTemplateVaSlice = createSlice({
    name: "masterTemplateVaStore",
    initialState: {
        webChatState: undefined,
        activityId: undefined,
        masterButtonsLoaded: false,
        webChatComponentKey: 0,
        homeButtonPayload: undefined,
        helpButtonPayload: undefined,
        personaContext: undefined
    },
    reducers: {
        setWebChatState(state, action) {
            state.webChatState = action.payload;
        },
        updateWebChatArgs(state, action) {
            if (state.webChatState) {
                state.webChatState.args = action.payload;
            }
        },
        setMasterButtonsLoadFlag(state, action) {
            state.masterButtonsLoaded = action.payload;
        },
        setActivityId(state, action) {
            state.activityId = action.payload;
        },
        renewWebChatComponentKey(state, action) {
            state.webChatComponentKey = state.webChatComponentKey + 1;
            // TODO: Following code may be removed or refactored once requirements evolve further...
            if (state.webChatState?.args)
                state.webChatState.args = action.payload;
        },
        setHomeButtonPayload(state, action) {
            state.homeButtonPayload = action.payload;
        },
        setHelpButtonPayload(state, action) {
            state.helpButtonPayload = action.payload;
        },
        setPersonaContext(state, action) {
            state.personaContext = action.payload;
        }

    }
});

export const masterTemplateVaSliceActions = masterTemplateVaSlice.actions;
export default masterTemplateVaSlice;